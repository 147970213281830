.TechnicalDisplay {
    margin-left: 10vw;
    margin-right: 10vw;

}

.TechnicalDisplay p {
    display: block;
    width: 100vw;
    font-size: 7.5vw;
    font-weight: bold;
    margin: 15px;
    border: 1px solid black;
    box-shadow: 0px 0px 2vw 1.5vw rgb(62, 70, 68);
}


.TechnicalDisplay-skills {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 80vw;
    margin-bottom: 200px;
}

.TechnicalDisplay-skills h6 {
    font-size: 1.5vw;
    margin-top: 0px;
}

.dev-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 10vw;
    row-gap: 2vw;
    padding: 5px;
}

@media only screen and (max-width:1080px) {
    .dev-icons {
        flex-basis: 10vh;
        padding: 2vw;
    }
    .TechnicalDisplay-skills{
        margin-bottom: 15vh;
    }
    .TechnicalDisplay-skills h6 {
        font-size: 3.5vw;
    }

    .dev-icons img{
        width: 15vw;
    }

}

@media only screen and (max-width:1080px) and (min-aspect-ratio: 1/1) {
    .dev-icons {
        flex-basis: 10vw;
        padding: 2vh;
    }

    .TechnicalDisplay-skills h6 {
        font-size: 3.5vh;
    }

    .dev-icons img{
        width: 15vh;
    }
}