.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logical{
  font-family: 'Share Tech', sans-serif;
  font-size: 5vh;
}

.creative{
  font-family: 'Great Vibes', cursive;
  font-size: 5vh;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image:linear-gradient(rgb(131, 141, 128),white);
}

.App footer {
  position: fixed;
  background-color: gray;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 10vh;
}

@media only screen and (max-width:1080px) {
  .App footer{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width:1080px)and(min-aspect-ratio: 1/1) {
  /* .logical{
    font-size: 10vh;
  }
  
  .creative{

    font-size: 10vh;
  } */
}
