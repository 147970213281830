.Blog p{
    margin:0px;
}

.Blog{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap; 
}
.Blog-title{
    background-color:rgb(46, 22, 51);
    padding: 5px;
    width:fit-content;
    display:flex;
    margin-left: 5vw;
    margin-bottom:5vh;
    flex-direction: column;
    margin-bottom: 5px;
    align-content:center;
    flex-wrap: wrap;
    box-shadow: 2px 2px 20px 2px black;
    color:rgb(236, 230, 230);
}

.Blog-content-container{
    border: 1px solid black;
    border-radius: 10px;
    background-color: bisque;
    width:88vw;
    margin-left: 5vw;
    margin-bottom: 2vh;
}
.Blog-content{
    margin-left: 1vw;
    margin-right:1vw;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap; 
    padding: 1vw;
    align-items: flex-start;
}

@media only screen and (max-width:1080px) {
    .Blog-title{
        margin-bottom: 1vh;
        max-width: 87vw;
    }
}