

/* .HamburgerMenu{
    position: absolute;
    right: 7.5vw;
    top: 5vh;
    display: inline-block;
    visibility: hidden;
    
  } */

  .HamburgerMenu{
    visibility: hidden;
    display: none;
  }
  
  .HamburgerMenu-line{
    border: 2px solid black;
    width: 35px;
    height:0px;
    margin-bottom: 10px;
    transition: transform .5s, width .5s, border .25s;
  }
  
  .HamburgerMenu-menu{
    position: absolute;
    right: .25vw;
    z-index: 1;
    visibility:hidden;
    opacity: 0;
    transition: visibility 0s, opacity .5s;
    display:flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .HamburgerMenu-line.top.active{
    transform-origin: center;
    width:35px;
    transform: rotate(45deg) translateX(14.75px) translateY(4.75px);
  }
  
  .HamburgerMenu-line.middle.active{
    transform-origin: center;
    transform: translateX(25px);
    width:0px;
    border: 2px solid black;
  }
  .HamburgerMenu-line.bottom.active{
    transform-origin: center;
    width:35px;
    transform: rotate(-45deg) translateX(14.75px) translateY(-4.75px);
  }
  
  .HamburgerMenu-menu.active{
    visibility:hidden;
    opacity:1;
    background-color: rgba(201, 184, 184, 0.90);
    border: 2px solid black;
    border-radius: 10%;
  }

  
  @media only screen and (max-width:1080px) {
    .HamburgerMenu{
      visibility: visible;
      display: flex;
      flex-direction: column;
      height:auto;
      margin-right: 4vw;
    }
    .HamburgerMenu-menu.active{
      visibility: visible;
      top: 10vh;
      right: 5.5vw;
    }
    
    a.active{
      font-weight: bold;
      font-size: 37px;
      font-family:'Great Vibes';
      color:black;
    }
    .HamburgerMenu-menu a{
      font-size: 5vw;
    }

    .HamburgerMenu-menu{
      width: 35vw;
    }
  }

  @media only screen and (max-width:1080px)and (min-aspect-ratio: 1/1) {
    .HamburgerMenu-menu.active{
      visibility: visible;
      top: 10vw;
      right: 5.5vh;
      
    }
    .HamburgerMenu-menu a{
      font-size: 5vh;
    }
    .HamburgerMenu-menu{
      width: 35vh;
    }
  }
