.ProjectDisplay{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    align-items: center;
    margin-bottom: 15vh;
}

.ProjectDisplay-project{
    box-shadow: 0px 0px 2vw 1.5vw rgb(62, 70, 68);
    border: 1px solid rgb(145, 129, 129);
    padding: 1vh 3vw;
    margin: 2vh 2vw;
}
.ProjectDisplay-project-image{
    width: 75vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.ProjectDisplay-project-description{
    font-size: 3vw;
}

@media only screen and (max-width:1080px){
    .ProjectDisplay-project a{
        font-size: 5vw;
    }
}