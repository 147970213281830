.CounterDisplay{
    display: flex;
    justify-content: center;
}

.CounterDisplay-digit{
    border: 1px solid black;
    margin: 0px;
    padding: 1px;
    width: 10px;
    height: 2vh;
}

@media only screen and (max-width:1080px) {
    .CounterDisplay-digit{
        width: 2.5vw;
    }
}
