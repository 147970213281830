.Nav {
    background-color: cadetblue;
    display: flex;
    flex-wrap: wrap;
    height: 10vh;
    margin: 1.5vh;
    border: 5px solid black;
    box-shadow: 0px 0px 2vw 1.5vw rgb(62, 70, 68);
    justify-content: space-between;
    align-items: center;

}

.Nav-site-title {
    justify-content: center;
    display: flex;
    margin-left: 5vw;
    align-content: center;
    flex-wrap: wrap;
}

.Nav-links {
    margin: 2vh 2vw;
}

.Nav a {
    margin: 40px auto auto 20px;
    font-size: 3vh;

}

.Nav a.active {
    font-weight: bold;
}

@media only screen and (max-width:1080px) {
    .Nav {
        min-height: 90px;
    }

    .Nav-links {
        margin: 0vh;
    }

    .Nav-links-browser {
        visibility: hidden;
        display: none;
    }

    .Nav-site-title {
        justify-content: flex-start;
        display: flex;
    }

    .Nav a {
        margin: 2px;
        font-size: 10vw;
        text-decoration: none;
    }
}

@media only screen and (max-width:1080px) and (min-aspect-ratio: 1/1) {
    .Nav .logical {
        font-size: 5vw;
    }

    .Nav .creative {
        font-size: 5vw;
    }
}