.ContactDisplay-contact-methods a{
    margin: 5px;
}

.ContactDisplay-contact-methods{
    margin: auto 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 4vw;
}

@media only screen and (max-width:1080px) {
    .ContactDisplay-contact-methods{
        flex-direction: column;

    }
}