.AboutDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutDisplay-content {
    width: 50vw;
    padding: 1vh 1vw;
    margin: 1vh 1vw;
}

.AboutDisplay-title {
    margin: 1vh 1vw;
}

@media only screen and (max-width:1080px) {
    .AboutDisplay-content {
        width: 70vw;
    }

}