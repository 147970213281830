.VisitorCounter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2vh;
}

.VisitorCounter p{
    display: block;
    margin: 2px;
}

.VisitorCounter-fact{
    font-size: 2vh;
}

@media only screen and (max-width:1080px) {
    
    .VisitorCounter-fact{
        visibility:hidden;
        height:0vh;
    }
}